import { closest } from '../../../shared/js/utils/dom';
import Shower from '../../../shared/js/modules/shower';

class MobileNav {
  constructor(params) {
    this.mobileToggle = document.getElementById('main-nav-mobile-toggle');
    this.menuItems = document.querySelectorAll('#main-menu > .menu-item');
    this.subNavToggles = document.querySelectorAll(
      '#main-menu .menu-item-has-children'
    );
    this.subNavItems = document.querySelectorAll('.sub-menu a');
    this.mainWrapper = document.querySelector('body');
    this.logo = document.querySelector('.header__logo');
    this.shower = null;
  }

  init = () => {
    this.mobileToggle &&
      this.mobileToggle.addEventListener(
        'click',
        event => this.toggleMobileNav(this.mobileToggle, this.logo),
        false
      );

    this.mobileToggle && this.startResizeObserver();

    this.menuItems.forEach(item => {
      item.addEventListener('focusin', this.focusHandler);

      item.addEventListener('focusout', this.focusHandler);

      item.addEventListener('mouseenter', event => {
        const parent = closest(item, '.menu-item-has-children');
        parent && parent.classList.add('is-active');
      });

      item.addEventListener('mouseleave', () => {
        const parent = closest(item, '.menu-item-has-children');
        parent && parent.classList.remove('is-active');
      });
    });

    this.subNavItems.forEach(item => {
      item.addEventListener(
        'click',
        event => {
          console.log(event);
          const link = closest(event.target, 'a');
          window.location.href = link.href;
        },
        {
          capture: false,
          once: false,
          passive: false,
        }
      );
    });
  };

  focusHandler = event => {
    const parent = event.target.parentElement;
    const subNav = closest(parent, '.sub-menu');
    const menuItemHasChildren = closest(parent, '.menu-item-has-children');
    const isInSubNav = subNav && closest(event.target, '.sub-menu') !== true;

    const isLastSubNavItem = parent === subNav.lastElementChild;
    if (isInSubNav || !menuItemHasChildren) return;

    if (event.type === 'focusin') this.onFocusIn(parent);
    if (event.type === 'focusout')
      this.onFocusOut(parent, isInSubNav, isLastSubNavItem);
  };

  onFocusIn = (item, isInSubNav) => {
    item.classList.add('is-active');
  };

  onFocusOut = (item, isInSubNav, isLastSubNavItem) => {
    if (!isInSubNav) return;

    item.classList.remove('is-active');
  };

  startResizeObserver = () => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const cr = entry.contentRect;

        if (
          cr.width <= 1024 - 15 &&
          this.mobileToggle.classList.contains(
            'main-nav__mobile-toggle--is-active'
          )
        ) {
          this.logo.classList.add('header__logo--is-active');
        } else {
          this.logo.classList.remove('header__logo--is-active');
          this.mobileToggle.classList.remove(
            'main-nav__mobile-toggle--is-active'
          );
        }
      }
    });

    resizeObserver.observe(this.mainWrapper);
  };

  initShower = () => {
    if (this.shower) return;
    for (let i = 0; i < this.subNavToggles.length; i++) {
      const subNavToggle = this.subNavToggles[i];
      const target = document.querySelector('.sub-menu', subNavToggle);
      const handler = subNavToggle;

      const shower = new Shower({
        target,
        handler,
        easing: 'easeInOutQuart',
        duration: 400,
        initOpened: false,
      });
      shower.init();
      this.shower = shower;
    }
  };

  toggleMobileNav = (mobileToggle, logo) => {
    if (mobileToggle.classList.contains('main-nav__mobile-toggle--is-active')) {
      document.body.classList.remove('nav--expanded');
      logo.classList.remove('header__logo--is-active');
      mobileToggle.classList.remove('main-nav__mobile-toggle--is-active');
    } else {
      document.body.classList.add('nav--expanded');
      logo.classList.add('header__logo--is-active');
      mobileToggle.classList.add('main-nav__mobile-toggle--is-active');
    }
    this.initShower();
  };
}
export default MobileNav;
