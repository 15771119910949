class BackToTop {
  constructor(params) {
    this.topButton = document.querySelector('#top-button');
  }

  init = () => {
    this.topButton &&
      this.topButton.addEventListener(
        'click',
        event => this.backToTop(this.topButton),
        false
      );
    this.topButton &&
      window.addEventListener(
        'scroll',
        event => this.showButton(this.topButton),
        false
      );
  };

  showButton() {
    if (window.scrollY > 400) {
      this.topButton.classList.add('top-button--active');
    } else {
      this.topButton.classList.remove('top-button--active');
    }
  }

  backToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
export default BackToTop;
