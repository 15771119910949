import Shower from '../../../shared/js/modules/shower';

class PageContent {
  constructor(params) {
    this.additionalContentToggle = document.querySelector(
      '.page-content__button'
    );
    this.mainWrapper = document.querySelector('body');
    this.showers = [];
  }

  init = () => {
    this.additionalContentToggle &&
      this.additionalContentToggle.addEventListener(
        'click',
        event => this.toggleBiographyDescription(this.additionalContentToggle),
        false
      );
    this.additionalContentToggle && this.initShowers();
  };

  initShowers = () => {
    if (this.showers.length !== 0) return;

    const target = document.querySelector(
      '.page-content__description--additional'
    );
    const handler = this.additionalContentToggle;

    const shower = new Shower({
      target,
      handler,
      easing: 'easeInOutQuart',
      duration: 400,
      initOpened: false,
    });
    shower.init();
    this.showers.push(shower);
  };

  toggleBiographyDescription = additionalContentToggle => {
    if (
      additionalContentToggle.classList.contains(
        'page-content__description--is-active'
      )
    ) {
      additionalContentToggle.classList.remove(
        'page-content__description--is-active'
      );
      additionalContentToggle.innerHTML =
        '+ <span>ausführliche Biografie anzeigen</span>';
    } else {
      additionalContentToggle.classList.add(
        'page-content__description--is-active'
      );
      additionalContentToggle.innerHTML = '- <span>Biografie einklappen</span>';
      this.initShowers();
    }
  };
}
export default PageContent;
